<div mat-dialog-title>
  <div class="title-container">
    <div class="title-modal d-flex f-align-center">
      <h1 class="title-modal">Massive actions</h1>
    </div>
    <span class="icon one-icon-close-v2" (click)="close()"></span>
  </div>

  <div [class.loading]="loading || isError || idResponse" mat-dialog-content>
    <div [class.hidden]="loading || isError || idResponse" class="content-container">
      <one-select #actionSelect label="Select action" [dataSource]="dataSource" [size]="size" (selectionChanged)="changeAction($event)"></one-select>
      <div [class.error]="sizeFile > maxSizeFile || isErrorFormat" class="file-input-wrapper">
        <input id="fileInput" type="file" accept=".csv" (change)="onFileSelected($event)" />
        <label class="label-wrapper" for="fileInput">
          <span [class.upload]="fileName" [class.error-text]="sizeFile > maxSizeFile || isErrorFormat">CSV</span>
          <i class="one-icon-share-document-v2" oneSuffix aria-hidden="true" [class.upload-icon]="fileName">Upload</i>
        </label>
        <div class="file-upload">
          <span class="file-name" *ngIf="fileName">{{ fileName }}</span>
        </div>
      </div>
      <div *ngIf="sizeFile > maxSizeFile" class="error-message">
        <span>{{ msjSizeError }}</span>
      </div>
      <div *ngIf="isErrorFormat" class="error-message">
        <span>*Incorrect file format or structure.</span>
      </div>
      <a [href]="templateLink" class="link" target="_blank">Accounts template</a>
    </div>
    <div *ngIf="loading" class="spinner">
      <div class="rp-loader"></div>
    </div>
    <div *ngIf="!loading && (isError || idResponse)" class="response-wrapper">
      <div *ngIf="isError" class="error-response">
        <img src="assets/images/failed.png" alt="failed">
        <span>Update failed, please try again.</span>
      </div>
      <div *ngIf="idResponse" class="success-response">
        <img src="assets/images/check.png" alt="check">
        <span>Request received</span>
        <span class="id">Confirmation ID: {{ idResponse }}</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading && !isError && !idResponse" mat-dialog-actions>
  <button class="rp-button rp-filled btn-cancel" (click)="close()">Cancel</button>
  <button class="rp-button rp-filled rp-primary" [disabled]="!fileName || sizeFile > maxSizeFile || !action || isErrorFormat" (click)="upload()">
    Upload file
  </button>
</div>

<div *ngIf="!loading && (isError || idResponse)" mat-dialog-actions class="btn-response">
  <button class="rp-button rp-filled rp-primary" (click)="close()">
    Got it
  </button>
</div>
