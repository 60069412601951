<fieldset [class.full-width]="fullWidth" [class.card]="isCard" [class.header-top]="isCardHeader">
  <legend>
    <div class="text">
      <span *ngIf="title; else titleTemplate" [className]="icon">{{ title }}</span>
      <span *ngIf="subtitle" class="subtitle">{{ subtitle }}</span>
    </div>
    <ng-content select="[id=actions]"></ng-content>
  </legend>
  <ng-content></ng-content>
</fieldset>
