import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { StoreAuxService } from '../../services/storeAux/store-aux.service';
import { Observable } from 'rxjs';

import { Account, AccountList, Config, FileResponse, RoleCompanyId } from '../definitions/accounts.models';
import { Paginate, Permission } from '../definitions/permissions.models';
import { SearchCriteria, UserType } from '../definitions/permissions.enums';
import { PermissionComponent } from '../definitions/component.models';

import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/states/app.state';

import { UtilPermissions } from '../utils/util-permissions.service';

@Injectable()
export class AccountsService extends UtilPermissions {
  userType: UserType;
  accountId: number;

  readonly BASE_PATH = 'accounts/';

  constructor(
    private readonly _http: HttpClient,
    private readonly _storeAuxService: StoreAuxService,
    _store: Store<AppState>
  ) {
    super(_store);
  }

  getAccounts(page: number, limit: number, name = ''): Observable<Permission<Paginate<AccountList>>> {
    const params = new HttpParams({
      fromObject: Object.assign({ page, limit, search_value: name, search_criteria: SearchCriteria.email })
    });

    return this._http.get<Permission<Paginate<AccountList>>>(this._getCountryUrl(), { params });
  }

  createAccount(body: Account): Observable<Permission<Account>> {
    return this._http.post<Permission<Account>>(this._getCountryUrl(), body);
  }

  editAccount(body: Account, id: number): Observable<Permission<Account>> {
    return this._http.put<Permission<Account>>(this._getCountryUrl() + id, body);
  }

  getAccount(id: number): Observable<Permission<Account>> {
    return this._http.get<Permission<Account>>(this._getCountryUrl() + id);
  }

  deleteAccount(id: number): Observable<Permission<unknown>> {
    return this._http.delete<Permission<unknown>>(this._getCountryUrl() + id);
  }

  getComponents(): Observable<Permission<PermissionComponent[]>> {
    return this._http.get<Permission<PermissionComponent[]>>(
      `${this._getCountryUrl()}components`,
      this.getHeaders(this.userType, this.accountId)
    );
  }

  getConfig(): Observable<Config> {
    return this._http.get<Config>(`${this._storeAuxService.getCountry(environment.urlRevenue)}/configuration`);
  }

  getRoleCompanyId(id: string): Observable<RoleCompanyId> {
    const base = this._storeAuxService.getCountry(environment.urlRevenue);
    const endpoint = `/zoho-company/${id}`;
    const urlService = `${base}${endpoint}`;
    return this._http.get<RoleCompanyId>(urlService);
  }

  uploadFile(image: File, option: string): Observable<FileResponse> {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('option', option);

    return this._http.post<FileResponse>(
      `${this._storeAuxService.getCountry(environment.urlRevenue)}/massive-actions/accounts`,
      formData
    );
  }

  private _getCountryUrl(): string {
    return `${this._storeAuxService.getCountry(environment.urlRevenue)}/${this.BASE_PATH}`;
  }
}
