import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { TradeComponent } from './trade/trade.component';
import { environment } from '../../environments/environment';
import { TradeCampaignPermalinkComponent } from './trade/components/trade-campaign-permalink/trade-campaign-permalink.component';

export const routes: Routes = [
  {
    path: '',
    data: {
      isMfe: true,
      module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
      hideBreadcrumbs: true
    },
    children: [
      {
        path: 'campaigns/:action/:id/country/:country',
        component: TradeCampaignPermalinkComponent,
        data: {
          module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
          hideBreadcrumbs: true
        }
      },
      {
        path: 'smart-campaigns/:action/:id/country/:country',
        component: TradeCampaignPermalinkComponent,
        data: {
          module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
          hideBreadcrumbs: true
        }
      },
      {
        path: 'campaigns',
        component: TradeComponent,
        data: {
          isMfe: true,
          module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
          hideBreadcrumbs: true,
          submodule: 'campaigns',
          reuse: true
        },
        children: [
          {
            path: '**',
            component: TradeComponent,
            data: {
              isMfe: true,
              module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: 'smart-campaigns',
        component: TradeComponent,
        data: {
          isMfe: true,
          module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
          hideBreadcrumbs: true,
          reuse: true
        },
        children: [
          {
            path: '**',
            component: TradeComponent,
            data: {
              isMfe: true,
              module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: 'cross-deals',
        component: TradeComponent,
        data: {
          isMfe: true,
          module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
          hideBreadcrumbs: true,
          submodule: 'smartCampaigns',
          reuse: true
        },
        children: [
          {
            path: '**',
            component: TradeComponent,
            data: {
              isMfe: true,
              module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: 'pricing',
        component: TradeComponent,
        data: {
          isMfe: true,
          module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
          hideBreadcrumbs: true,
          submodule: 'pricing',
          reuse: true
        },
        children: [
          {
            path: '**',
            component: TradeComponent,
            data: {
              isMfe: true,
              module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: 'booking',
        component: TradeComponent,
        data: {
          isMfe: true,
          module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
          hideBreadcrumbs: true,
          submodule: 'booking',
          reuse: true
        },
        children: [
          {
            path: '**',
            component: TradeComponent,
            data: {
              isMfe: true,
              module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: 'config',
        component: TradeComponent,
        data: {
          isMfe: true,
          module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
          hideBreadcrumbs: true,
          submodule: 'config',
          reuse: true
        },
        children: [
          {
            path: '**',
            component: TradeComponent,
            data: {
              isMfe: true,
              module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: 'history',
        component: TradeComponent,
        data: {
          isMfe: true,
          module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
          hideBreadcrumbs: true,
          submodule: 'history',
          reuse: true
        },
        children: [
          {
            path: '**',
            component: TradeComponent,
            data: {
              isMfe: true,
              module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
              hideBreadcrumbs: true,
              reuse: true
            }
          }
        ]
      },
      {
        path: '',
        redirectTo: 'campaigns',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TradeRoutingModule {}
