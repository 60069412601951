<app-actions [id]="id" type="Account" [action]="VIEW" [service]="deleteAccount"></app-actions>
<ng-container *ngIf="data$ | async as data">
  <one-card class="no-padding general-card">
    <app-form-group icon="one-icon-user-tooltip" title="General Information" [isCard]="true">
      <app-view-form-field label="Account ID" [value]="id" [disabled]="true"></app-view-form-field>
      <app-view-form-field label="Account title" [value]="data.title"></app-view-form-field>
      <app-view-form-field label="Email Address" [value]="data.email"></app-view-form-field>
      <app-view-form-field label="Company ID" [value]="data.company_id"></app-view-form-field>
      <app-view-form-field label="Role" [value]="data.role_id?.label"></app-view-form-field>
    </app-form-group>
  </one-card>

  <one-card [hidden]="!showMakerAssigmentAccount" class="no-padding">
    <app-form-group icon="one-icon-diagonal-tag" title="Maker Account Assignment" [isCard]="true">
      <app-view-form-field label="Country" [value]="country$ | async" [disabled]="true"></app-view-form-field>
      <app-view-form-field label="City">
        <span class="rp-chip selection" *ngFor="let entity of data.statement_values.cities">{{ entity.name }}</span>
      </app-view-form-field>
      <app-view-form-field label="Vertical">
        <span class="rp-chip selection" *ngFor="let entity of data.statement_values.verticals">{{ entity.name }}</span>
      </app-view-form-field>
      <app-view-form-field label="Maker">
        <span class="rp-chip selection" *ngFor="let entity of data.statement_values.makers">{{ entity.name }}</span>
      </app-view-form-field>
      <app-view-form-field label="Trademark">
        <span class="rp-chip selection" *ngFor="let entity of data.statement_values.trademarks">{{ entity.name }}</span>
      </app-view-form-field>
      <app-view-form-field label="Subcategory">
        <span class="rp-chip selection" *ngFor="let entity of data.statement_values.subcategories">
          {{ entity.name }}
        </span>
      </app-view-form-field>
    </app-form-group>
  </one-card>

  <one-card [hidden]="!showStoreAssigmentAccount" class="no-padding">
    <app-form-group icon="one-icon-diagonal-tag" title="Store Account Assignment" [isCard]="true">
      <app-view-form-field label="Country" [value]="country$ | async" [disabled]="true"></app-view-form-field>
      <app-view-form-field label="Vertical">
        <span class="rp-chip selection" *ngFor="let entity of data.store_statement_values.vertical">{{
          entity.name
        }}</span>
      </app-view-form-field>
      <app-view-form-field label="Store brand">
        <span class="rp-chip selection" *ngFor="let entity of data.store_statement_values.store_brand">{{
          entity.name
        }}</span>
      </app-view-form-field>
    </app-form-group>
  </one-card>

  <one-card class="no-padding">
    <app-form-group icon="one-icon-settings" title="Plan Details" [subtitle]="planLabel" [fullWidth]="true" [isCard]="true" [isCardHeader]="true">
      <ng-container *ngFor="let plan of data.plans; index as i">
        <div class="form-wrapper">
          <div class="title-wrapper">
            <span class="title-plan">Plan {{ i + 1 }}</span>
          </div>
          <div
            *ngIf="
              plan.expiration_status === ACCOUNT_STATUS.per_expire || plan.expiration_status === ACCOUNT_STATUS.expired
            "
            class="one-icon-exclamation-circle alert"
            [style.--color]="ACCOUNT_STATUS_COLOR[plan.expiration_status]"
          >
            <span>
              {{ ACCOUNT_STATUS_MESSAGE[plan.expiration_status] }}
            </span>
          </div>
          <div class="row-col w-100">
            <div class="row w-50">
              <app-view-form-field label="KAM Email" [value]="plan.kam_email"></app-view-form-field>
            </div>
            <div class="row w-50">
              <app-view-form-field label="Tier Type" [value]="plan.tier_name"></app-view-form-field>
            </div>
          </div>
          <div class="row-col w-100">
            <div class="row w-50">
              <app-view-form-field label="Start Date">
                <div class="one-icon-after-calendar">{{ plan.start_at | date }}</div>
              </app-view-form-field>
            </div>
            <div class="row w-50">
              <app-view-form-field label="End Date">
                <div class="one-icon-after-calendar">{{ plan.ends_at | date }}</div>
              </app-view-form-field>
            </div>
          </div>
          <div *ngIf="data.plans.length > 1" class="span-2"></div>
        </div>
      </ng-container>
    </app-form-group>
  </one-card>
</ng-container>
