import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Config, FileResponse, MassiveActions } from './../../../../definitions/accounts.models';
import { SelectComponent, SelectSize } from '@rappi/ui/select';
import { AccountsService } from './../../../../services/accounts.service';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { EMPTY, Observable, Subject } from 'rxjs';
import { MASSIVE_ACTIONS_DATASOURCE } from './../../../../definitions/permissions.constants';
import { Entity } from './../../../../definitions/permissions.models';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-massive-actions-dialog',
  templateUrl: './massive-actions-dialog.component.html',
  styleUrls: ['./massive-actions-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MassiveActionsDialogComponent implements OnInit, OnDestroy {
  @ViewChild('actionSelect') actionSelect: SelectComponent<Entity>;

  dataSource: MassiveActions[] = MASSIVE_ACTIONS_DATASOURCE;
  size: SelectSize = 'default';
  fileName: string | null = null;
  selectedFile: File | null = null;
  sizeFile = 0;
  maxSizeFile: number;
  templateLink: string;
  action: string | null = null;
  loading = false;
  isErrorFormat = false;
  isError = false;
  idResponse: string;
  msjSizeError: string;

  destroySubject$: Subject<void> = new Subject();

  constructor(
    public _dialogRef: MatDialogRef<MassiveActionsDialogComponent>,
    private readonly _accountService: AccountsService
  ) {}

  ngOnInit(): void {
    this.getConfig();
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  upload() {
    this.loading = true;

    const option = this.action.toUpperCase();
    this._accountService
      .uploadFile(this.selectedFile, option)
      .pipe(
        takeUntil(this.destroySubject$),
        catchError(this.catchFormatError.bind(this)),
        finalize(() => (this.loading = false))
      )
      .subscribe((res: FileResponse) => (this.idResponse = res.data?.operation_id));
  }

  close() {
    this._dialogRef.close();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    this.fileName = null;
    this.selectedFile = null;
    this.sizeFile = 0;
    this.isErrorFormat = false;

    if (input && input.files && input.files.length > 0) {
      this.fileName = input.files[0].name;
      this.selectedFile = input.files[0];
      this.sizeFile = this.selectedFile.size / 1000000;
    }
  }

  getConfig(): void {
    this._accountService
      .getConfig()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: Config) => {
        this.templateLink = res.massive_actions_accounts?.upload_template_link ?? '#';
        this.maxSizeFile = res.massive_actions_accounts?.max_upload_size_mb ?? 0.103001;
        this.msjSizeError = res.massive_actions_accounts?.max_upload_size_error_msg ?? '';
      });
  }

  changeAction(action: Entity): void {
    this.action = action.name;
  }

  private catchFormatError(err: HttpErrorResponse): Observable<never> {
    if (err.status === 422) {
      this.isErrorFormat = true;
    } else {
      this.isError = true;
    }

    return EMPTY;
  }
}
