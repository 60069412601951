import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';

import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsType } from '@rappi/ui/alerts';
import { AccountsService } from '../../../../services/accounts.service';
import { TierService } from '../../../../services/tier.service';
import { FiltersService } from '../../../../services/filters.service';

import { LazyScrollableDataSource } from '@rappi/common';
import { MasterDataEntity } from '../../../../../shared/enums';
import { Account, Plan, RoleCompanyId, Statement } from '../../../../definitions/accounts.models';
import { COUNTRY_NAME } from '../../../../../definitions/countries.constants';
import { PLURAL_STATEMENT, SINGULAR_STATEMENT } from '../../../../definitions/account-enums';
import { AlertsService } from '../../../../../../app/services/alerts/alerts.service';

import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../store/states/app.state';
import { StoreName } from '../../../../../store/definitions/store.constants';
import { CountryState } from '../../../../../store/states/country.state';
import { Observable, of, Subject } from 'rxjs';
import { map, skip, takeUntil, tap, debounceTime, distinctUntilChanged, finalize, filter } from 'rxjs/operators';

import { SelectComponent, SelectConfig } from '@rappi/ui/select';
import { Entity, Paginate, Permission } from '../../../../definitions/permissions.models';
import { ALL_CONDITION } from '../../../../definitions/permissions.constants';
import {
  AccountStatus,
  AccountStatusColor,
  AccountStatusMessage,
  PermissionAction,
  SearchCriteria
} from '../../../../definitions/permissions.enums';
import { Tier } from '../../../../definitions/tier.models';
import { FilterParams, FilterRequest, FiltersDependency } from '../../../../definitions/filters.models';
import { ACTIONS } from '../../../../definitions/actions.enums';
import { HttpErrorResponse } from '@angular/common/http';
import { Config } from './../../../../definitions/accounts.models';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManagerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('countriesSelect') countriesSelect: SelectComponent<Entity>;
  @ViewChildren('tierTypeSelect') tierTypeSelects: QueryList<SelectComponent<Tier>>;
  @ViewChild('citySelect') citiesSelect: SelectComponent<Entity>;
  @ViewChild('verticalSelect') verticalsSelect: SelectComponent<Entity>;
  @ViewChild('subcategorySelect') subcategoriesSelect: SelectComponent<Entity>;
  @ViewChild('makerSelect') makersSelect: SelectComponent<Entity>;
  @ViewChild('trademarkSelect') trademarksSelect: SelectComponent<Entity>;
  @ViewChild('verticalStoreSelect') verticalStoreSelect: SelectComponent<Entity>;
  @ViewChild('countriesSelectStore') countriesSelectStore: SelectComponent<Entity>;
  @ViewChild('storeSelect') storeSelect: SelectComponent<Entity>;

  readonly PAGE = 1;
  readonly LIMIT = 10;
  readonly ACTIONS = PermissionAction;

  action: string;
  form: FormGroup = new FormGroup({
    title: new FormControl(null, Validators.required),
    email: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._!#$%&'*+/=?`{|}~^-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ]),
    company_id: new FormControl(null),
    plans: new FormArray([this.getPlansForm()]),
    statement: new FormGroup({
      city: new FormControl(null, Validators.required),
      vertical: new FormControl(null, Validators.required),
      subcategory: new FormControl(null, Validators.required),
      maker: new FormControl(null, Validators.required),
      trademark: new FormControl(null, Validators.required),
      store: new FormControl(null),
      verticalStore: new FormControl(null)
    }),
    rol: new FormControl(null)
  });
  viewUrl: string;
  tableUrl: string;
  toDay = new Date();

  plansNumber: number;

  currenciesDataSource: Entity[] = [{ name: 'USD', id: 'USD' }];

  countriesDataSource: Observable<Entity[]>;

  tierTypeDataSource = new LazyScrollableDataSource<Tier>(this.getTiers.bind(this), 10);

  citiesDataSource = new LazyScrollableDataSource<Entity>(this.getCities.bind(this), this.LIMIT, this.PAGE);

  verticalsDataSource = new LazyScrollableDataSource<Entity>(this.getVerticals.bind(this), this.LIMIT, this.PAGE);

  verticalsStoreDataSource = new LazyScrollableDataSource<Entity>(
    this.getVerticalStore.bind(this),
    this.LIMIT,
    this.PAGE
  );

  storeDataSource = new LazyScrollableDataSource<Entity>(this.getStore.bind(this), this.LIMIT, this.PAGE);

  subcategoriesDataSource = new LazyScrollableDataSource<Entity>(
    this.getSubcategoriesList.bind(this),
    this.LIMIT,
    this.PAGE
  );

  makersDataSource = new LazyScrollableDataSource<Entity>(this.getMakersList.bind(this), this.LIMIT, this.PAGE);

  trademarksDataSource = new LazyScrollableDataSource<Entity>(this.getTrademarksList.bind(this), this.LIMIT, this.PAGE);

  isRappiDomain: boolean;
  defaultDisabled = true;
  role = '';
  errorCompanyId = { flagError: false, message: '' };
  loadingCompany = false;
  allSelectedByCities = true;
  allSelectedByVertical = true;
  firstEmailValidation = true;
  planLabel: string;

  readonly MASTER_DATA_ENTITY = MasterDataEntity;

  readonly SELECT_CONFIG: SelectConfig = {
    includeAll: true,
    panelClass: 'one-select',
    search: true
  };
  id: number;
  tierSelected: Tier[] = [];

  readonly TIERS_TYPES_VALUES = {
    canSeeMaker: ['maker', 'internal', 'agency'],
    canSeeStore: ['stores', 'internal', 'agency']
  };

  deleteAccount: (id: number) => Observable<Permission<unknown>>;

  propagateChanges = true;

  destroySubject$: Subject<void> = new Subject();

  readonly ACCOUNT_STATUS = AccountStatus;
  readonly ACCOUNT_STATUS_COLOR = AccountStatusColor;
  readonly ACCOUNT_STATUS_MESSAGE = AccountStatusMessage;

  private readonly ACCOUNT_CREATION_MESSAGE = 'Account created successfully';
  private readonly ACCOUNT_CREATION_ERROR = 'There was an error trying to create account';

  private readonly ACCOUNT_UPDATE_MESSAGE = 'Account updated successfully';
  private readonly ACCOUNT_UPDATE_ERROR = 'There was an error trying to update account with id';

  constructor(
    private readonly _cd: ChangeDetectorRef,
    private readonly _accountsService: AccountsService,
    private readonly _alertsService: AlertsService,
    private readonly _router: Router,
    private readonly _store: Store<AppState>,
    private readonly _tierService: TierService,
    private readonly _filtersService: FiltersService
  ) {
    const routeArr = this._router.url.split('/');

    this.id = Number(routeArr[4]);
    this.action = routeArr[3];

    routeArr.length = 3;

    this.tableUrl = routeArr.join('/');
    this.viewUrl = this.id ? `${this.tableUrl}/${PermissionAction.view}/${this.id}` : null;
    this.deleteAccount = (id: number) =>
      this._accountsService.deleteAccount(id).pipe(tap(() => this.goTo(this.tableUrl)));

    this._store
      .pipe(
        select(StoreName.country),
        skip(1),
        takeUntil(this.destroySubject$),
        tap(() => this.goTo(this.tableUrl))
      )
      .subscribe();

    this.getConfig();
  }

  get plans(): FormArray {
    const plans = this.form.get('plans') as FormArray;
    Array.from(plans.controls).forEach((el) => el.patchValue({}));
    return plans;
  }

  get statement(): FormGroup {
    return this.form.get('statement') as FormGroup;
  }

  get maker(): FormControl {
    return this.statement.get('maker') as FormControl;
  }

  get trademark(): FormControl {
    return this.statement.get('trademark') as FormControl;
  }

  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

  ngAfterViewInit() {
    this.countriesDataSource = this._store.pipe(
      select(StoreName.country),
      map(({ countrySelected }: CountryState) => [
        { name: COUNTRY_NAME[countrySelected] as string, id: countrySelected }
      ]),
      tap((countries: Entity[]) => {
        this.countriesSelect.setSelection(countries[0]);
        this.countriesSelectStore.setSelection(countries[0]);
      })
    );

    this._cd.detectChanges();

    if (this.action === ACTIONS.edit) {
      this._accountsService.getAccount(this.id).subscribe(
        ({ data }: Permission<Account>) => {
          this.plansNumber = data.plans.length;

          this.setPlansValues(data);

          this.setStatementsElements(data);

          this.setStoreStatements(data);

          this.getChangesCompanyId(data.company_id);

          this.form.get('rol').setValue({
            id: data.role_id?.id,
            name: data.role_id?.label
          });

          this._cd.detectChanges();
        },
        ({ error: { message } }: HttpErrorResponse) => {
          this._alertsService.openAlerts(message || 'There was an error trying to get account', AlertsType.error);
        }
      );
    } else {
      this.setSelectedAllByDefault(this.citiesDataSource, this.citiesSelect, { value: this.allSelectedByCities });
      this.setSelectedAllByDefault(this.verticalsDataSource, this.verticalsSelect, {
        value: this.allSelectedByVertical
      });
    }

    this.isCreatingPO(this.action === ACTIONS.create);

    this.getChangesEmail(this.action === ACTIONS.create);
  }

  setPlansValues(data: Account): void {
    if (data.plans.length > 0) {
      data.plans.forEach(({ tier_id, tier_name, expiration_status, tier_type }: Plan, i: number) => {
        if (i > 0) {
          this.addNewPlan();
        }
        this.tierTypeSelects
          .toArray()
          [i].setSelection({ id: tier_id, name: tier_name, type: tier_type, expiration_status } as Tier);
        this.isAvailableRangeControl(i, expiration_status);
      });
    } else {
      this.getChangesNewPO(0);
    }

    this.form.patchValue({
      ...data,
      statement: Object.keys(data.statement).length ? this._mapStatementsValues(data.statement_values) : {}
    });
  }

  setStatementsElements(data): void {
    Object.keys(SINGULAR_STATEMENT).forEach((key: string) => {
      if (data.statement_values[key]) {
        (data.statement_values[key] as Entity[]).forEach((el: Entity, i: number) => {
          this.propagateChanges = i === (data.statement_values[key] as Entity[]).length - 1;

          if (el.id === ALL_CONDITION) {
            (this[`${key}Select`] as SelectComponent<Entity>).toggleAll();
            (this[`${key}Select`] as SelectComponent<Entity>).emitAllSelection();
          } else {
            (this[`${key}Select`] as SelectComponent<Entity>).setMultipleSelection(el);
          }
        });
      }
    });
  }

  setStoreStatements(data): void {
    const storeSelects = { store_brand: 'storeSelect', vertical: 'verticalStoreSelect' };
    Object.keys(data.store_statement_values).forEach((key: string) => {
      if (data.store_statement_values[key]) {
        data.store_statement_values[key].forEach((element) => {
          if (element.id === ALL_CONDITION) {
            (this[storeSelects[key]] as SelectComponent<Entity>).toggleAll();
            (this[storeSelects[key]] as SelectComponent<Entity>).emitAllSelection();
          } else {
            (this[storeSelects[key]] as SelectComponent<Entity>).setMultipleSelection(element);
          }
        });
      }
    });
  }

  getChangesEmail(isCreate: boolean): void {
    this.email.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((newValue: string) => {
      const roleControl = this.form.get('rol');
      const companyControl = this.form.get('company_id');
      if (this.email.valid) {
        const isRappiDomain = newValue.endsWith('@rappi.com');
        const wasRappiDomain = this.isRappiDomain;

        if (this.action === ACTIONS.edit && roleControl.value?.id === '') {
          roleControl.setValue(null);
        }

        if (!this.firstEmailValidation && isRappiDomain !== wasRappiDomain) {
          if (isRappiDomain || wasRappiDomain) {
            this.cleanFormsStoreOrMaker();
          }
        }

        if (isCreate) {
          this.tierTypeSelects.toArray().forEach((tier) => tier.setSelection(null));
          this.tierSelected = [];
        }

        this.tierTypeDataSource.setFilterValue(null);

        this.isRappiDomain = isRappiDomain;

        if (this.isRappiDomain) {
          roleControl.setValidators(Validators.required);
          roleControl.enable();
        } else {
          roleControl.setValidators(null);
          companyControl.enable();
        }

        roleControl.updateValueAndValidity({ emitEvent: true });

        this.defaultDisabled = false;
        this.firstEmailValidation = false;
      } else {
        this.role = '';
        roleControl.setValue(null);
        companyControl.setValue(null);
        roleControl.disable();
        companyControl.disable();
        this.loadingCompany = false;
        this.setErrorCompanyId(false, '');
      }
    });
  }

  isAvailableRangeControl(i: number, status: string): void {
    if (status === this.ACCOUNT_STATUS.expired) {
      this.plans.controls[i].get('start_at').disable();
      this.plans.controls[i].get('ends_at').disable();
    } else {
      this.plans.controls[i].get('start_at').disable();
      this.plans.controls[i].get('ends_at').enable();
    }
  }

  getTiers(page: number, limit: number, name: string): Observable<Tier[]> {
    return this._tierService.getTiers(page + 1, limit, name, this.email.value).pipe(
      map(
        ({ data: { records } }: Permission<Paginate<Tier>>) =>
          records?.map((element) => ({
            ...element,
            name: `${element.name} [${element.type}]`,
            disabled: !!this.tierSelected && this.tierSelected.some((tier) => tier.id === element.id)
          })) || []
      )
    );
  }

  getCities(page: number, size: number, name: string): Observable<Entity[]> {
    return this._getEntityList(page, size, name, 'city' as MasterDataEntity);
  }

  getVerticals(page: number, size: number, name: string): Observable<Entity[]> {
    return this._getEntityList(page, size, name, this.MASTER_DATA_ENTITY.vertical);
  }

  getVerticalStore(page: number, size: number, name: string): Observable<Entity[]> {
    return this._getStore(page, size, name, this.MASTER_DATA_ENTITY.vertical);
  }

  getSubcategoriesList(page: number, size: number, name: string): Observable<Entity[]> {
    const dependency: FiltersDependency[] = [
      {
        name: this.MASTER_DATA_ENTITY.maker,
        data: (this.maker.value || [{ id: ALL_CONDITION }]).map(({ id }: Entity) => id)
      },
      {
        name: this.MASTER_DATA_ENTITY.trademark,
        data: (this.trademark.value || [{ id: ALL_CONDITION }]).map(({ id }: Entity) => id)
      }
    ];
    const condition = this.maker.valid && this.trademark.valid;
    return condition ? this._getEntityList(page, size, name, this.MASTER_DATA_ENTITY.subcategory, dependency) : of([]);
  }

  getMakersList(page: number, size: number, name: string): Observable<Entity[]> {
    return this._getEntityList(page, size, name, this.MASTER_DATA_ENTITY.maker);
  }

  getTrademarksList(page: number, size: number, name: string): Observable<Entity[]> {
    const dependency: FiltersDependency[] = [
      {
        name: this.MASTER_DATA_ENTITY.maker,
        data: (this.maker.value || [{ id: ALL_CONDITION }]).map(({ id }: Entity) => id)
      }
    ];
    return this.maker.valid
      ? this._getEntityList(page, size, name, this.MASTER_DATA_ENTITY.trademark, dependency)
      : of([]);
  }

  setPlanValue(selection: Entity | Tier, key: string, id: number, entity = null): void {
    (this.plans.controls[id] as FormGroup).patchValue({ [key]: selection });

    if (entity && selection && selection?.expiration_status !== this.ACCOUNT_STATUS.expired) {
      this.updateTiersSelected();
    }
  }

  updateTiersSelected(index?: number, action = false): void {
    this.tierSelected = [];

    if (action) {
      const filteredArray = this.tierTypeSelects.toArray().filter((_, i) => i !== index);
      this.tierTypeSelects.reset(filteredArray);
    }

    this.tierTypeSelects.forEach((tier) => {
      if (!!tier.control.value && tier.control.value?.expiration_status !== this.ACCOUNT_STATUS.expired) {
        this.tierSelected.push(tier.control.value as Tier);
      }
    });

    if (!action) {
      this.tierTypeDataSource.setFilterValue(null);
    }
  }

  cleanFormsStoreOrMaker(): void {
    this.role = '';
    const title = this.form.get('title');
    const city = this.statement.get('city');
    const vertical = this.statement.get('vertical');
    const maker = this.statement.get('maker');
    const trademark = this.statement.get('trademark');
    const subcategory = this.statement.get('subcategory');
    const verticalStore = this.statement.get('verticalStore');
    const store = this.statement.get('store');

    title.setValidators(Validators.required);
    title.updateValueAndValidity();
    city.setValidators(Validators.required);
    city.updateValueAndValidity();
    vertical.setValidators(Validators.required);
    vertical.updateValueAndValidity();
    maker.setValidators(Validators.required);
    maker.updateValueAndValidity();
    trademark.setValidators(Validators.required);
    trademark.updateValueAndValidity();
    subcategory.setValidators(Validators.required);
    subcategory.updateValueAndValidity();
    verticalStore.clearValidators();
    verticalStore.updateValueAndValidity();
    store.clearValidators();
    store.updateValueAndValidity();
    this.cleanAccountAssignmentControls();
  }

  cleanAccountAssignmentControls(): void {
    this.tierSelected = [];
    this.form.get('title').setValue(null);
    this.form.get('company_id').setValue(null);
    this.form.get('rol').setValue(null);
    this.statement.get('maker').setValue(null);
    this.statement.get('trademark').setValue(null);
    this.statement.get('subcategory').setValue(null);
    this.statement.get('store').setValue(null);
    this.setErrorCompanyId(false, '');
    this.resetPlansToNull();

    this.setSelectedAllByDefault(this.citiesDataSource, this.citiesSelect, { value: this.allSelectedByCities });
    this.setSelectedAllByDefault(this.verticalsDataSource, this.verticalsSelect, { value: this.allSelectedByVertical });

    this.makersSelect.clearSelection();
    this.trademarksSelect.clearSelection();
    this.subcategoriesSelect.clearSelection();
    this.storeSelect.clearSelection();
    this.tierTypeSelects.forEach((tier) => tier.clearSelection());
    this.verticalStoreSelect.clearSelection();
  }

  resetPlansToNull(): void {
    const plansArray = this.form.get('plans') as FormArray;
    plansArray.controls.forEach((control) => {
      control.reset({
        kam_email: null,
        tier_id: 0,
        start_at: null,
        ends_at: null,
        id: null,
        expiration_status: null
      });
      control.get('start_at').enable();
    });
  }

  cleanStores(): void {
    this.statement.get('store').setValue(null);
    this.storeSelect.clearSelection();
  }

  evaluationFieldsRequiredMakerAndStore(): void {
    const haveMakerFormValues = this.getHaveMakerFormValues();
    const haveStoreFormValues = this.getHaveStoreFormValues();
    this.fieldRequiredMakerForm(haveMakerFormValues, haveStoreFormValues);
    this.fieldRequiredStoreForm(haveMakerFormValues, haveStoreFormValues);
  }

  getHaveMakerFormValues(form = this.statement.getRawValue()): boolean {
    return form.city?.length || form.maker?.length || form.vertical?.length;
  }

  getHaveStoreFormValues(form = this.statement.getRawValue()): boolean {
    return form.verticalStore?.length || form.store?.length;
  }

  fieldRequiredMakerForm(haveMakerFormValues: boolean, haveStoreFormValues: boolean): void {
    if (haveMakerFormValues) {
      const verticalStore = this.statement.get('verticalStore');
      const store = this.statement.get('store');
      const vertical = this.statement.get('vertical');
      const maker = this.statement.get('maker');
      const trademark = this.statement.get('trademark');
      const subcategory = this.statement.get('subcategory');
      this.statement.get('city').updateValueAndValidity();
      vertical.setValidators(Validators.required);
      vertical.updateValueAndValidity();
      maker.setValidators(Validators.required);
      maker.updateValueAndValidity();
      trademark.setValidators(Validators.required);
      trademark.updateValueAndValidity();
      subcategory.setValidators(Validators.required);
      subcategory.updateValueAndValidity();
      if (!haveStoreFormValues) {
        verticalStore.clearValidators();
        verticalStore.updateValueAndValidity();
        store.clearValidators();
        store.updateValueAndValidity();
      } else {
        verticalStore.setValidators(Validators.required);
        verticalStore.updateValueAndValidity();
        store.setValidators(Validators.required);
        store.updateValueAndValidity();
      }
    }
  }

  fieldRequiredStoreForm(haveMakerFormValues: boolean, haveStoreFormValues: boolean): void {
    if (haveStoreFormValues) {
      const verticalStoreControl = this.statement.get('verticalStore');
      const storeControl = this.statement.get('store');
      const cityControl = this.statement.get('city');
      const verticalControl = this.statement.get('vertical');
      const makerControl = this.statement.get('maker');
      const trademarkControl = this.statement.get('trademark');
      const subcategoryControl = this.statement.get('subcategory');
      verticalStoreControl.setValidators(Validators.required);
      verticalStoreControl.updateValueAndValidity();
      storeControl.setValidators(Validators.required);
      storeControl.updateValueAndValidity();
      if (!haveMakerFormValues) {
        cityControl.clearValidators();
        cityControl.updateValueAndValidity();
        verticalControl.clearValidators();
        verticalControl.updateValueAndValidity();
        makerControl.clearValidators();
        makerControl.updateValueAndValidity();
        trademarkControl.clearValidators();
        trademarkControl.updateValueAndValidity();
        subcategoryControl.clearValidators();
        subcategoryControl.updateValueAndValidity();
      } else {
        cityControl.setValidators(Validators.required);
        cityControl.updateValueAndValidity();
        verticalControl.setValidators(Validators.required);
        verticalControl.updateValueAndValidity();
        makerControl.setValidators(Validators.required);
        makerControl.updateValueAndValidity();
        trademarkControl.setValidators(Validators.required);
        trademarkControl.updateValueAndValidity();
        subcategoryControl.setValidators(Validators.required);
        subcategoryControl.updateValueAndValidity();
      }
    }
  }

  setStatementValue(selection: Entity[], key: string, reset?: string) {
    const allSelected = this[`${PLURAL_STATEMENT[key]}Select`].allSelected;
    this.resetSelectAll(allSelected, key);
    const condition = ['maker', 'city', 'vertical', 'verticalStore'].includes(key)
      ? allSelected && !selection.length
      : allSelected || !selection.length;
    this.statement.patchValue({
      [key]: condition ? [{ id: ALL_CONDITION }] : selection
    });

    if (reset && this.propagateChanges) {
      (this[`${reset}Select`] as SelectComponent<Entity>).clearSelection();
      setTimeout(() => {
        (this[`${reset}DataSource`] as LazyScrollableDataSource<Entity>).setFilterValue(null);
      }, 1000);
    }
  }

  resetSelectAll(allSelected: boolean, key: string): void {
    switch (key) {
      case 'city':
        this.allSelectedByCities = !allSelected;
        break;
      case 'vertical':
        this.allSelectedByVertical = !allSelected;
        break;
    }
  }

  goTo(url: string) {
    this._router.navigateByUrl(url);
  }

  onSubmit(f: FormGroup) {
    if (f.invalid) {
      f.markAllAsTouched();
      return;
    }

    const preRequest = f.getRawValue() as Account;

    preRequest.plans.forEach((el) => {
      if (el.tier_id) {
        el.tier_id = (el.tier_id as Tier)?.id || Number(el.tier_id);
      }
      if (el.start_at) {
        el.start_at = new Date(el.start_at).toJSON();
      }
      if (el.ends_at) {
        el.ends_at = new Date(el.ends_at).toJSON();
      }
    });

    Object.keys(preRequest.statement).forEach((key) => {
      const data = preRequest.statement[key] as Entity[];
      if (Array.isArray(data)) {
        preRequest.statement[key] = data.length > 0 ? data.map((el) => el?.id) : null;
      } else {
        preRequest.statement[key] = data ? [data] : null;
      }

      if (key === 'maker' && preRequest.statement[key] === null) {
        preRequest.statement['trademark'] = null;
        preRequest.statement['subcategory'] = null;
      }
    });

    this.preAction(preRequest);
  }

  preAction(preRequest): void {
    const MESSAGE: string = this.id ? this.ACCOUNT_UPDATE_MESSAGE : this.ACCOUNT_CREATION_MESSAGE;
    const ERROR: string = this.id ? `${this.ACCOUNT_UPDATE_ERROR} ${this.id}` : this.ACCOUNT_CREATION_ERROR;

    preRequest = {
      ...preRequest,
      role_id: preRequest.rol?.id
    };

    delete preRequest.rol;

    const body = this.getBody(preRequest);

    const action$ = this.id
      ? this._accountsService.editAccount(body, this.id)
      : this._accountsService.createAccount(body);

    action$.subscribe(
      ({ data: { id } }: Permission<Account>) => {
        this._alertsService.openAlerts(MESSAGE, AlertsType.success);
        this.goTo(this.viewUrl || `${this.tableUrl}/${PermissionAction.view}/${id}`);
      },
      ({ error: { message } }: HttpErrorResponse) => {
        this._alertsService.openAlerts(message || ERROR, AlertsType.error);
      }
    );
  }

  getBody(data) {
    const { verticalStore, store } = data.statement || {};

    const body = {
      store_statement: {
        vertical: verticalStore?.length ? verticalStore.map(Number) : null,
        store_brand: store?.length ? store.map(Number) : null
      },
      ...data
    };

    delete body.statement.verticalStore;
    delete body.statement.store;

    return body;
  }

  addNewPlan() {
    this.plans.push(this.getPlansForm());
    this._cd.detectChanges();
  }

  removePlan(index: number): void {
    this.plans.removeAt(index);
    this.updateTiersSelected(index, true);
    this._cd.detectChanges();
    setTimeout(() => {
      this.tierTypeDataSource = new LazyScrollableDataSource<Tier>(this.getTiers.bind(this), 10);
    }, 400);
  }

  getChangesNewPO(index: number): void {
    this.plans.controls[index]
      .get('start_at')
      .valueChanges.pipe(takeUntil(this.destroySubject$))
      .subscribe((value) => {
        if (value) {
          this.plans.controls[index].get('ends_at').enable();
        }
      });
  }

  isCreatingPO(isCreate: boolean) {
    if (isCreate) {
      this.getChangesNewPO(0);
    }
  }

  getPlansForm(): FormGroup {
    return new FormGroup({
      kam_email: new FormControl(null, Validators.email),
      tier_id: new FormControl(null),
      start_at: new FormControl(null),
      ends_at: new FormControl({ value: null, disabled: true }),
      id: new FormControl(null),
      expiration_status: new FormControl(null)
    });
  }

  setSelectedAllByDefault(
    dataSource: LazyScrollableDataSource<Entity>,
    selector: SelectComponent<Entity>,
    flag: { value: boolean }
  ): void {
    dataSource
      .getDataObservable()
      .pipe(filter((data) => data.length && flag.value))
      .subscribe(() => {
        flag.value = false;
        selector.activeAllSelectByDefault();
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  getStore(page: number, size: number, name: string): Observable<Entity[]> {
    const vertical = this.statement.get('verticalStore').value;
    const dependency = {
      vertical: Array.isArray(vertical) ? vertical.map((element) => element.id) : [-1]
    };
    return this._getStore(page, size, name, this.MASTER_DATA_ENTITY.store_brand, dependency);
  }

  getConfig(): void {
    this._accountsService
      .getConfig()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: Config) => (this.planLabel = res.account?.plans_label ?? ''));
  }

  getChangesCompanyId(id = this.form.get('company_id').value): void {
    this.loadingCompany = true;
    if (!!id && id !== '') {
      this._accountsService
        .getRoleCompanyId(id)
        .pipe(
          takeUntil(this.destroySubject$),
          finalize(() => (this.loadingCompany = false))
        )
        .subscribe(
          (res: RoleCompanyId) => {
            this.role = res.role;
            this.setErrorCompanyId(false, '');
          },
          (error: HttpErrorResponse) => {
            this.role = '';
            this.form.get('rol').setValue(null);
            const message = error.status === 404 ? `*${error.error?.message}` : '*Invalid company ID, try again';
            this.setErrorCompanyId(true, message);
          }
        );
    } else {
      this.loadingCompany = false;
      this.role = '';
      this.form.get('rol').setValue(null);
      this.setErrorCompanyId(false, '');
    }
  }

  setErrorCompanyId(flag: boolean, message: string): void {
    Object.assign(this.errorCompanyId, { flagError: flag, message });
  }

  private _mapStatementsValues(statement: Statement): { [key: string]: Entity[] | { id: number }[] } {
    return {
      city: statement.cities[0].id === ALL_CONDITION ? [{ id: ALL_CONDITION }] : statement.cities,
      vertical: statement.verticals[0].id === ALL_CONDITION ? [{ id: ALL_CONDITION }] : statement.verticals,
      maker: statement.makers[0].id === ALL_CONDITION ? [{ id: ALL_CONDITION }] : statement.makers,
      trademark: statement.trademarks[0].id === ALL_CONDITION ? [{ id: ALL_CONDITION }] : statement.trademarks,
      subcategory: statement.subcategories[0].id === ALL_CONDITION ? [{ id: ALL_CONDITION }] : statement.subcategories
    };
  }

  private _getStore(
    page: number,
    limit: number,
    search: string,
    entity: MasterDataEntity,
    dependency = null
  ): Observable<Entity[]> {
    const params: FilterParams = { page, limit, search_criteria: SearchCriteria.name, search_value: search || '' };
    const body: FilterRequest = { entity, module: 'permissions' };
    if (dependency) {
      body.dependency = dependency;
    }

    return this._filtersService
      .getStores(body, params)
      .pipe(map(({ data: { records } }: Permission<Paginate<Entity>>) => records || []));
  }

  private _getEntityList(
    page: number,
    limit: number,
    search: string,
    entity: MasterDataEntity,
    dependency?: FiltersDependency[]
  ): Observable<Entity[]> {
    const params: FilterParams = { page, limit, search_criteria: SearchCriteria.name, search_value: search || '' };
    const body: FilterRequest = { entity, dependency, module: 'permissions' };

    return this._filtersService
      .getEntities(body, params)
      .pipe(map(({ data: { records } }: Permission<Paginate<Entity>>) => records || []));
  }
}
