<ng-container *ngIf="(account$ | async)?.email as email">
  Supporting this account: <span class="email">{{ email }}</span>
</ng-container>

<ng-container *ngIf="type$ | async as type">
  <div class="wrapper" *ngIf="this.isRootPath$ | async">
    <app-link-tabs [tabs]="LINK_TABS"></app-link-tabs>
    <div class="btn-wrapper">
      <button *ngIf="userType === IS_ADMIN && type === 'accounts' && isUserAllowed" class="rp-button rp-outlined rp-primary" (click)="massiveActions()">
        Massive actions
      </button>
      <button
        [ngStyle]="{
          display:
            userType === IS_ADMIN && !(account$ | async)?.email && HIDDEN_CREATE_BUTTON_TABS.includes(type)
              ? 'none'
              : ''
        }"
        class="rp-button rp-filled rp-primary one-icon-times"
        [routerLink]="[type, CREATOR_PATH]"
      >
        Create {{ SINGULAR_NAME[type] }}
      </button>
    </div>
  </div>
</ng-container>

<router-outlet></router-outlet>
